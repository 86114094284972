const NotFound = () => {
  return (
    <div className="container" style={{textAlign: "center"}}>
      <h1>404 Not Found</h1>
      <br />
      <h4>There's nothing here</h4>
    </div>
  )
}

export default NotFound
